<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        <v-text-field v-model="dateFrom" placeholder="Datum von" />
        <v-text-field v-model="dateTo" placeholder="Datum bis" />
        <v-btn icon @click.stop="setFilter()" :disabled="!dateFrom || !dateTo">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="orderItems"
            item-key="orderNumber"
        >
          <template v-slot:item.orderCustomer="props">
            {{ props.item.orderCustomer.firstName }}
            {{ props.item.orderCustomer.lastName }}
          </template>

          <template v-slot:item.orderDate="{ item }">
            {{ item.orderDate | moment('DD.MM.YYYY') }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    expanded: [],
    headers: [
      {
        text: "Bestellnummer",
        align: "start",
        sortable: true,
        value: "orderNumber"
      },
      {
        text: "Mitarbeiter",
        align: "start",
        sortable: false,
        value: "orderCustomer"
      },
      {
        text: "Bestelldatum",
        align: "end",
        sortable: true,
        value: "orderDate"
      },
      {
        text: "Bestellstatus",
        align: "end",
        sortable: true,
        value: "stateMachineState.technicalName"
      },
    ],
    filter: null,
    dateFrom: null,
    dateTo: null
  }),

  computed: {
    ...mapGetters("order", ["orders"]),

    lineItems: function() {
      return this.orders; // List orders by line items
    },

    orderItems: function() {
      return this.orders?.elements;
    }
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions("order", ["list"]),

    init() {
      this.listOrders();
    },

    listOrders() {
      this.list({
        limit: 25,
        filter: this.filter,
        associations: {
          lineItems: {},
        },
        includes: {
          order: ["orderNumber", "orderDate", "orderCustomer", "lineItems", "stateMachineState"]
        }
      })
    },

    setFilter() {
      this.filter = [];

      this.filter.push({
        type: "range",
        field: "orderDate",
        parameters: {
          gte: this.dateFrom,
          lte: this.dateTo
        }
      })

      this.listOrders();
    }
  }
}
</script>